import React, { useCallback, useContext, useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { AppContext } from '../Context'

export default function SecondStep() {
  const { formValues, handleChange, handleFileChange, handleBack, handleNext, variant, margin } = useContext(AppContext)
  const { city, date, phone, agreenemt, resume } = formValues
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const isError = useCallback(
    () =>
      Object.keys({ city, date, phone, agreenemt, resume }).some(
        (name) => (formValues[name].required && !formValues[name].value) || formValues[name].error
      ),
    [formValues, city, date, phone, agreenemt, resume]
  )

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label='Ciudad'
            name='city'
            placeholder='Tu ciudad'
            value={city.value}
            onChange={handleChange}
            error={!!city.error}
            helperText={city.error}
            required={city.required}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            label='Fecha de nacimiento'
            name='date'
            type='date'
            defaultValue={date.value}
            onChange={handleChange}
            required={date.required}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label='Teléfono'
            name='phone'
            placeholder='i.e: xxx-xxx-xxxx'
            value={phone.value}
            onChange={handleChange}
            error={!!phone.error}
            helperText={phone.error}
            required={phone.required}
          />
        </Grid>
        <Grid item xs={12}>
        <InputLabel htmlFor="input-cv">CV</InputLabel>
          <Input
            type='file'
            name='resume'
            onChange={handleFileChange}
            inputProps={{ required: resume.required }}
          />
          {resume.error && <FormHelperText error>{resume.error}</FormHelperText>}
        </Grid>
        <Grid item xs={12} container alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                checked={agreenemt.value}
                onChange={handleChange}
                name='agreenemt'
                color='primary'
                required={agreenemt.required}
              />
            }
            label='Acepto los términos y condiciones'
          />
          <IconButton onClick={handleOpen} sx={{ ml: 1, color: "#1E9FAB" }}>
            <HelpOutlineIcon />
          </IconButton>
          <FormHelperText error={!!agreenemt.error}>{agreenemt.error}</FormHelperText>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxHeight: '80%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflow: 'auto'
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Términos y Condiciones
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Al completar y enviar el formulario de postulación, usted acepta estos términos y condiciones. Le recomendamos que lea atentamente esta sección para comprender cómo utilizaremos y protegeremos su información personal.
            <br /><br />
            <strong>Recopilación de Información</strong>
            <br />
            Para procesar su postulación, recopilaremos la siguiente información personal:
            <ul>
              <li>Nombre completo</li>
              <li>Dirección de correo electrónico</li>
              <li>Número de teléfono</li>
              <li>Ciudad de residencia</li>
              <li>Fecha de nacimiento</li>
              <li>Otra información que pueda ser relevante para su postulación</li>
            </ul>
            <br />
            <strong>Uso de la Información</strong>
            <br />
            La información que usted proporciona será utilizada exclusivamente para los siguientes propósitos:
            <ul>
              <li>Evaluar su idoneidad para las posiciones disponibles en nuestra empresa.</li>
              <li>Ponernos en contacto con usted en relación a su postulación.</li>
              <li>Realizar análisis internos para mejorar nuestros procesos de reclutamiento.</li>
            </ul>
            <br />
            <strong>Almacenamiento y Seguridad</strong>
            <br />
            Nos comprometemos a proteger su información personal y mantenerla confidencial. Implementamos medidas de seguridad adecuadas para proteger su información contra el acceso no autorizado, la alteración, la divulgación o la destrucción.
            <br /><br />
            <strong>Compartición de Información</strong>
            <br />
            No compartiremos su información personal con terceros, excepto en los siguientes casos:
            <ul>
              <li>Cuando sea necesario para cumplir con una obligación legal.</li>
              <li>Con proveedores de servicios que nos asistan en el proceso de reclutamiento, siempre y cuando estos proveedores estén sujetos a obligaciones de confidencialidad.</li>
            </ul>
            <br />
            <strong>Retención de Información</strong>
            <br />
            Conservaremos su información personal durante el tiempo necesario para cumplir con los fines para los cuales fue recopilada, o según lo exija la ley. Si su postulación no es exitosa, podemos retener su información para considerarla en futuras oportunidades de empleo, a menos que usted nos indique lo contrario.
            <br /><br />
            <strong>Derechos del Usuario</strong>
            <br />
            Usted tiene derecho a acceder, rectificar o eliminar su información personal. También puede retirar su consentimiento para el uso de su información en cualquier momento. Para ejercer estos derechos, puede contactarnos a través de la dirección de correo electrónico proporcionada en nuestro sitio web.
            <br /><br />
            <strong>Modificaciones de los Términos y Condiciones</strong>
            <br />
            Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento. Cualquier cambio será notificado a través de nuestro sitio web. Le recomendamos revisar periódicamente esta sección para mantenerse informado sobre cómo protegemos su información personal.
            <br /><br />
            <strong>Contacto</strong>
            <br />
            Si tiene alguna pregunta o inquietud sobre estos términos y condiciones, puede contactarnos a través de la dirección de correo electrónico proporcionada en nuestro sitio web.
          </Typography>
          <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 2 }}>
            Cerrar
          </Button>
        </Box>
      </Modal>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
        <Button onClick={handleBack} sx={{ mr: 1 }}>
          Atrás
        </Button>
        <Button variant='contained' disabled={isError()} color='primary' onClick={!isError() ? handleNext : () => null}>
          Siguiente
        </Button>
      </Box>
    </>
  )
}

