import React, { createContext, useCallback, useMemo, useReducer } from 'react'
import { initialValues } from './initialValues'

const isText = /^[A-Z ]+$/i
const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
const isPhone = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4,6})$/ // us
const isZip = /^[0-9]{5}([- /]?[0-9]{4})?$/ // us
const isNumber = /^\d+$/

// Applied to all fields

type Variant = 'outlined' | 'standard' | 'filled'
type Margin = 'dense' | 'normal' | 'none'

const variant: Variant = 'standard'
const margin: Margin = 'normal'

export declare type ValidationSchema = Record<
  string,
  {
    value?: any
    error?: string
    required?: boolean
    validate?: 'text' | 'number' | 'email' | 'phone' | 'zip' | 'checkbox' | 'select'
    minLength?: number
    maxLength?: number
    helperText?: string
  }
>

type ContextProps = {
  activeStep: number
  formValues: ValidationSchema
  // eslint-disable-next-line no-unused-vars
  handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked?: boolean): void
  handleFileChange(event: React.ChangeEvent<HTMLInputElement>): void
  handleNext(): void
  handleBack(): void
  variant: Variant
  margin: Margin
}

export const AppContext = createContext<ContextProps>({
  activeStep: 0,
  formValues: initialValues,
  handleChange() {},
  handleFileChange() {},
  handleNext() {},
  handleBack() {},
  variant,
  margin
})

interface ProviderProps {
  children: React.ReactNode
}

type State = {
  activeStep: number
  formValues: ValidationSchema
}

type Action =
  | { type: 'increase' }
  | { type: 'decrease' }
  | { type: 'form-value'; name: string; fieldValue: any }
  | { type: 'form-error'; name: string; error: string }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'increase':
      return {
        ...state,
        activeStep: state.activeStep + 1
      }
    case 'decrease':
      return {
        ...state,
        activeStep: state.activeStep - 1
      }
    case 'form-value':
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.name]: {
            ...state.formValues[action.name],
            value: action.fieldValue
          }
        }
      }
    case 'form-error':
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.name]: {
            ...state.formValues[action.name],
            error: action.error
          }
        }
      }

    default:
      return state
  }
}

export function StepsProvider({ children }: ProviderProps) {
  const [{ activeStep, formValues }, dispatch] = useReducer(reducer, {
    activeStep: 0,
    formValues: initialValues
  })

  // Proceed to next step
  const handleNext = useCallback(() => dispatch({ type: 'increase' }), [])
  // Go back to prev step
  const handleBack = useCallback(() => dispatch({ type: 'decrease' }), [])

  // Handle form change
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked?: boolean) => {
      const { type, name, value } = event.target

      const fieldValue = type === 'checkbox' ? checked : value

      dispatch({ type: 'form-value', name, fieldValue })

      const fieldName = initialValues[name]
      if (!fieldName) return

      const { required, validate, minLength, maxLength, helperText } = fieldName

      let error = ''

      if (required && !fieldValue) error = 'Este campo es obligatorio.'
      if (minLength && value && value.length < minLength) error = `Minimo ${minLength} caracteres son requeridos.`
      if (maxLength && value && value.length > maxLength) error = 'Maximo de caracteres excedido!'
      if (validate) {
        switch (validate) {
          case 'text':
            if (value && !isText.test(value)) error = helperText || 'Este campo solo acepta texto.'
            break

          case 'number':
            if (value && !isNumber.test(value)) error = helperText || 'Este campo solo acepta numeros.'
            break

          case 'email':
            if (value && !isEmail.test(value)) error = helperText || 'Por favor ingrese una dirección de correo válido.'
            break

          case 'phone':
            if (value && !isPhone.test(value))
              error = helperText || 'Por favor ingrese un numero telefonico valido. i.e: xxx-xxx-xxxx'
            break

          case 'zip':
            if (value && !isZip.test(value)) error = helperText || 'Por favor ingrese un código postal.'
            break

          case 'checkbox':
            if (!checked) error = helperText || 'Por favor ingrese un valor valido.'
            break

          case 'select':
            if (!value) error = helperText || 'Por favor seleccione un valor.'
            break

          default:
            break
        }
      }

      dispatch({ type: 'form-error', name, error })
    },
    []
  )

  // Handle file change
  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, files } = event.target
      const file = files?.[0] || null

      dispatch({ type: 'form-value', name, fieldValue: file })

      const fieldName = initialValues[name]
      if (!fieldName) return

      const { required, helperText } = fieldName

      let error = ''
      if (required && !file) error = 'Este campo es obligatorio.'

      dispatch({ type: 'form-error', name, error })
    },
    []
  )

  const constextValue = useMemo(
    () => ({
      activeStep,
      formValues,
      handleChange,
      handleFileChange,
      handleNext,
      handleBack,
      variant,
      margin
    }),
    [activeStep, formValues, handleChange, handleFileChange, handleNext, handleBack]
  )

  return (
    <AppContext.Provider value={constextValue}>
      <div className='mui-step-form'>{children}</div>
    </AppContext.Provider>
  )
}
