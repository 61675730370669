import React, { memo } from 'react'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

function Footer() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Cybersinn Solutions 2024 © '}
      <Link color='inherit' href='https://cybersinn.com'>
        Web
      </Link>
    </Typography>
  )
}

export default memo(Footer)
