import React from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

export default function Success() {
  return (
    <>
      <Typography variant='h2' align='center' color='#1E9FAB' sx={{ py: 4 }}>
        Muchas gracias!
      </Typography>
      <Typography component='p' align='center'>
        Nos pondremos en contacto contigo a través de tu correo electrónico para darte más detalles e instrucciones. ¡Estamos emocionados de que quieras unirte a nuestro equipo!
      </Typography>
      <div style={{textAlign: "center", marginTop: "25px"}}>
          <Button onClick={() => {
              window.location.href = "https://cybersinn.com";
          }}>
            Volver
          </Button>
      </div>
    </>
  )
}
