import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import axios from 'axios'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import ErrorIcon from '@mui/icons-material/Error'
import { AppContext } from '../Context'

export default function Confirm() {
  const { formValues, handleBack, handleNext } = useContext(AppContext)
  const { firstName, lastName, email, gender, date, city, phone, resume } = formValues

  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const handleSubmit = async () => {
    let formData = new FormData();

    Object.keys(formValues).forEach((name) => {
      formData.append(name, formValues[name].value);
    });

    const apiUrl = process.env.REACT_APP_API_URL || '';
    const apiKey = process.env.REACT_APP_API_KEY || '';

    if (!apiUrl || !apiKey) {
      setErrorMessage('API URL or API Key is not defined');
      setErrorModalOpen(true);
      return;
    }

    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'multipart/form-data'
        }
      })

      console.log(response.data)
      handleNext()
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.data && error.response.data.message) {
          setErrorMessage(error.response.data.message)
        } else {
          setErrorMessage('Ocurrió un error al procesar el formulario.')
        }
      } else {
        console.error('Error submitting the form', error)
        setErrorMessage('Ocurrió un error inesperado.')
      }

      setErrorModalOpen(true)
    }
  }

  const handleCloseErrorModal = () => {
    setErrorModalOpen(false)
  }

  return (
    <>
      <List disablePadding>
        <ListItem>
          <ListItemText primary='Nombre' secondary={firstName.value || 'No provisto'} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary='Apellido' secondary={lastName.value || 'No provisto'} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary='Email' secondary={email.value || 'No provisto'} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary='Sexo' secondary={gender.value || 'No provisto'} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary='Fecha de nacimiento' secondary={date.value || 'No provisto'} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary='Ciudad' secondary={city.value || 'No provisto'} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary='Teléfono' secondary={phone.value || 'No provisto'} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary='CV' secondary={resume.value ? resume.value.name : 'No provisto'} />
        </ListItem>
      </List>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
        <Button sx={{ mr: 1 }} onClick={handleBack}>
          Atras
        </Button>
        <Button variant='contained' color='success' onClick={handleSubmit}>
          Confirmar datos
        </Button>
      </Box>

      <Dialog open={errorModalOpen} onClose={handleCloseErrorModal}>
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ErrorIcon sx={{ color: '#bb2124', fontSize: '30px', mr: 1 }} />
            Error al enviar los datos:
          </Box>
        </DialogTitle>
        <DialogContent>
          <p>{errorMessage}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorModal} color='primary'>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
