import React, { memo } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

function Header() {
  return (
    <AppBar position='static' style={{backgroundColor: "#FFFFFF", justifyContent: "space-between", paddingInline: "9vw", display: "flex"}}>
      <Toolbar>
        <Typography variant='h6' color='#1E9FAB' noWrap>
          Portal Cybersinn Solutions
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default memo(Header)
